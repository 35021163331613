import { sendKycEmailService } from '@/services/profile/userProfileService';

const field = 'sendKycLink';

export default async ({ commit }) => {
  try {
    commit('setIsLoading', { field });
    const { response } = await sendKycEmailService();
    if (!response.success) {
      commit('setIsError', { field });
    } else {
      commit('setData', {
        _state: field,
        _data: response,
      });
    }
    return response;
  } catch (error) {
    commit('setIsError', { field });
    return error;
  } finally {
    commit('setIsReady', { field });
  }
};
