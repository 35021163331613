export interface PaginationData {
  current_page: number;
  total_pages: number;
  per_page: number;
  records: number;
}

export const paginateResponse = {
  current_page: 1,
  total_pages: 1,
  per_page: 10,
  records: 0,
};
