import { downloadPdfService as service } from '../../../../services/subscription/subscriptionService';

const field = 'download_pdf';

export default async ({ commit }, { code }) => {
  commit('setIsLoading', { field });

  const params = {
    reference_number: code,
  };

  try {
    const { response } = await service(params);

    commit('setIsReady', { field });

    return response;
  } catch (error) {
    commit('setIsError', { field });
    throw error;
  }
};
