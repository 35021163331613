import { algorithmsName } from '@/config/constants';
import randomDecimal from '@commons/helpers/generators/generateRandomDecimal';
import randomNumber from '@commons/helpers/generators/generateRandomNumber';

export default () => ({
  success: true,
  data: {
    allocation: randomDecimal(100000, 2),
    algorithm: [
      {
        name: algorithmsName[randomNumber(0, algorithmsName.length)],
        allocation: randomDecimal(900000, 2),
        exposure: randomDecimal(900000, 2),
        exposure_percentage: randomDecimal(100, 2),
        status: 'Active',
        color: '#ffcc00',
      },
      {
        name: algorithmsName[randomNumber(0, algorithmsName.length)],
        allocation: randomDecimal(100000, 2),
        exposure: randomDecimal(900000, 2),
        exposure_percentage: randomDecimal(100, 2),
        status: 'Deactive',
        color: '#ff6600',
      },
      {
        name: algorithmsName[randomNumber(0, algorithmsName.length)],
        allocation: randomDecimal(100000, 2),
        exposure: randomDecimal(900000, 2),
        exposure_percentage: randomDecimal(100, 2),
        status: 'Active',
        color: '#cc33ff',
      },
      {
        name: algorithmsName[randomNumber(0, algorithmsName.length)],
        allocation: randomDecimal(100000, 2),
        exposure: randomDecimal(900000, 2),
        exposure_percentage: randomDecimal(100, 2),
        status: 'Active',
        color: '#3399ff',
      },
      {
        name: algorithmsName[randomNumber(0, algorithmsName.length)],
        allocation: randomDecimal(100000, 2),
        exposure: randomDecimal(900000, 2),
        exposure_percentage: randomDecimal(100, 2),
        status: 'Deactive',
        color: '#66cc33',
      },
      {
        name: algorithmsName[randomNumber(0, algorithmsName.length)],
        allocation: randomDecimal(100000, 2),
        exposure: randomDecimal(900000, 2),
        exposure_percentage: randomDecimal(100, 2),
        status: 'Deactive',
        color: '#ff3333',
      },
      {
        name: algorithmsName[randomNumber(0, algorithmsName.length)],
        allocation: randomDecimal(100000, 2),
        exposure: randomDecimal(900000, 2),
        exposure_percentage: randomDecimal(100, 2),
        color: '#33ccff',
        status: 'Active',
      },
    ],
  },
});
