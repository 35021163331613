import { subscriptionUrls } from '@/api/apiUrls';
import http from '@/helpers/http';
import { httpMethods } from '../../config/constants';

// Adapters
import adapterCalculateSubscription from './adapters/calculateSubscription';
import adapterChangeSubscription from './adapters/changeSubscription';
import adapterConfirmSubscription from './adapters/confirmSubscription';
import adapterCancelSubscription from './adapters/cancelSubscription';
import adapterDownloadPdf from './adapters/downloadPdf';
import adapterExistingSubscription from './adapters/existingSubscription';
import adapterRenewSubscription from './adapters/renewSubscription';

// Mockdata
import mockCalculateSubscription from './mocks/calculateSubscription';
import { mockCancelSubscription } from './mocks/cancelSubscription';
import { mockChangeSubscription } from './mocks/changeSubscription';
import { mockDownloadPdf } from './mocks/downloadPdf';
import mockExistingSubscription from './mocks/existingSubscription';
import { mockRenewSubscription } from './mocks/renewSubscription';
import { mockSubscription } from './mocks/confirmSubscription';

const calculateSubscriptionService = async ({ body }) => {
  return await http({
    method: httpMethods.get,
    urlService: subscriptionUrls.calculateSubscription(),
    mockdata: mockCalculateSubscription,
    params: body,
    adapter: adapterCalculateSubscription,
    debug: false,
  });
};

const cancelSubscriptionService = async () => {
  return await http({
    method: httpMethods.patch,
    urlService: subscriptionUrls.cancelSubscription(),
    mockdata: mockCancelSubscription,
    adapter: adapterCancelSubscription,
    debug: false,
  });
};

const changeSubscriptionService = async (id, { body }) => {
  return await http({
    method: httpMethods.put,
    urlService: subscriptionUrls.changeSubscription(id),
    mockdata: mockChangeSubscription,
    adapter: adapterChangeSubscription,
    contentType: 'application/json',
    body,
    debug: false,
  });
};

const confirmSubscriptionService = async ({ body }) => {
  const { id } = body;
  delete body.id;
  return await http({
    method: httpMethods.post,
    urlService: subscriptionUrls.confirmSubscription(id),
    body,
    mockdata: mockSubscription,
    adapter: adapterConfirmSubscription,
    debug: false,
  });
};

const downloadPdfService = async (params) => {
  return await http({
    method: httpMethods.get,
    urlService: subscriptionUrls.downloadPdf(),
    params,
    mockdata: mockDownloadPdf,
    adapter: adapterDownloadPdf,
    contentType: 'application/pdf',
    debug: false,
    responseType: 'blob',
  });
};

const getExistingSubscriptionService = async () => {
  return await http({
    method: httpMethods.get,
    urlService: subscriptionUrls.existingSubscription(),
    mockdata: mockExistingSubscription,
    adapter: adapterExistingSubscription,
    debug: false,
  });
};

const renewSubscriptionService = async ({ body }) => {
  return await http({
    method: httpMethods.patch,
    urlService: subscriptionUrls.renewSubscription(),
    body,
    mockdata: mockRenewSubscription,
    adapter: adapterRenewSubscription,
    contentType: 'application/json',
    debug: false,
  });
};

export {
  calculateSubscriptionService,
  cancelSubscriptionService,
  changeSubscriptionService,
  confirmSubscriptionService,
  downloadPdfService,
  getExistingSubscriptionService,
  renewSubscriptionService,
};
