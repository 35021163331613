export default () => ({
  success: true,
  data: [
    {
      id: 1,
      name: 'Network1',
    },
    {
      id: 2,
      name: 'Network2',
    },
    {
      id: 3,
      name: 'Network3',
    },
    {
      id: 4,
      name: 'Network4',
    },
  ],
});
