import randomNumber from '@commons/helpers/generators/generateRandomNumber';

const invalidFields = () => ({
  success: false,
  data: {
    data: { error: '' },
  },
});

const downloadSuccess = () => ({
  success: true,
  data: {
    message: 'Successfully download',
  },
});

const downloadScenarios = [downloadSuccess, invalidFields];

export const mockDownloadPdf = () => {
  const rand = randomNumber(0, downloadScenarios.length);

  if (typeof downloadScenarios[rand] !== 'undefined') {
    return downloadScenarios[rand]();
  }
  return 'dowmload';
};
