import { paginateResponse } from '@commons/services/common/adapters/pagination.adapter';

const adapterPortfolio = {
  success: true,
  data: {},
  paginate: {
    ...paginateResponse,
    per_page: 4,
  },
};

export default adapterPortfolio;
