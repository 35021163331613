import randomNumber from '@commons/helpers/generators/generateRandomNumber';
import { riskLevel, algorithmsName } from '@/config/constants';

export default () => ({
  success: true,
  data: {
    id: randomNumber(100000, 10000000000),
    name: algorithmsName[randomNumber(0, algorithmsName.length)],
    risk: riskLevel[randomNumber(0, riskLevel.length)],
    performance: randomNumber(0, 100),
    plots: [
      {
        date: '2023-10-03 00:00:00',
        performance_percentage: 44.78,
        daily_returns_percentage: -1.44,
        id: '66fbaa5c5c27b8532ba15e04',
      },
      {
        date: '2023-10-04 00:00:00',
        performance_percentage: 43.98,
        daily_returns_percentage: -0.8,
        id: '66fbaa5c5c27b8532ba15e05',
      },
      {
        date: '2023-10-05 00:00:00',
        performance_percentage: 45.52,
        daily_returns_percentage: 1.54,
        id: '66fbaa5c5c27b8532ba15e06',
      },

      {
        date: '2024-09-29 00:00:00',
        performance_percentage: 45.14,
        daily_returns_percentage: -1.22,
        id: '66fbaa5c5c27b8532ba15f6e',
      },
      {
        date: '2024-09-30 00:00:00',
        performance_percentage: 44.27,
        daily_returns_percentage: -0.87,
        id: '66fbaa5c5c27b8532ba15f6f',
      },
    ],
  },
});
