<script setup>
import { computed } from "vue";

const props = defineProps({
  isLoading: {
    default: () => false,
    type: Boolean,
  },
  text: String,
  type: {
    default: () => "theme",
    type: String,
  },
  className: {
    default: () => "",
    type: String,
  },
  buttonDisable: Boolean,
});

const emit = defineEmits(["click"]);

const textToDisplay = computed(() => props.text || props.type);

const handleClick = () => {
  emit("click");
};
</script>

<template>
  <button
    @click="handleClick"
    :class="`btn btn-${type} ${className}`"
    :disabled="buttonDisable || isLoading"
  >
    <span v-if="!isLoading">{{ textToDisplay }}</span>
    <span v-else><i class="bi bi-arrow-repeat bi-white spin"></i></span>
  </button>
</template>
