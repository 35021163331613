// import generateRandomNumber from "@commons/helpers/generators/generateRandomNumber";

export default () => ({
  success: true,
  data: {
    status: 'PROCESS',
    time_next_link_s: 0,
    message: 'No kycStatus found!',
  },
});
