import { paginateResponse } from '@commons/services/common/adapters/pagination.adapter.ts';

const adapterWalletList = {
  success: true,
  data: [],
  paginate: {
    ...paginateResponse,
    per_page: 4,
  },
};

export default adapterWalletList;
