import { changePasswordService } from '@/services/profile/userProfileService';

const field = 'changePassword';

export default async ({ commit }, body) => {
  try {
    commit('setIsLoading', { field });
    const { response } = await changePasswordService(body);
    if (!response.error) {
      commit('setIsReady', { field });
      commit('setData', {
        _state: field,
        _data: response,
      });
    } else {
      commit('setIsError', { field });
    }
    return response;
  } catch (e) {
    commit('setIsError', { field });
    return {
      success: false,
      message: e.message,
    };
  }
};
