import { verifyEmailService } from '@/services/auth/authService';

const field = 'verifyEmail';

export default async ({ commit }, params) => {
  try {
    commit('setIsLoading', { field });
    const { response } = await verifyEmailService(params);
    if (response.error) {
      commit('setIsError', { field });
    } else {
      commit('setIsReady', { field });
    }
    return response;
  } catch (e) {
    commit('setIsError', { field });
    return {
      success: false,
      message: e.message,
    };
  }
};
