import { algorithmsName, riskLevel } from '@/config/constants';
import randomNumber from '@commons/helpers/generators/generateRandomNumber';

const riskLevelMock = () => ({
  success: true,
  data: {
    average_risk: riskLevel[randomNumber(0, riskLevel.length)],
    highest_risk_algorithm: algorithmsName[randomNumber(0, algorithmsName.length)],
    risk_percentage: randomNumber(0, 100),
  },
});

export default riskLevelMock;
