import randomNumber from '@commons/helpers/generators/generateRandomNumber';

const invalidFields = () => ({
  success: false,
  data: {
    data: { error: '' },
  },
});

const successCancelation = () => ({
  success: true,
  data: {
    message: 'Successfully canceled',
  },
});

const subscriptionScenarios = [successCancelation, invalidFields];

export const mockCancelSubscription = () => {
  const rand = randomNumber(0, subscriptionScenarios.length);

  if (typeof subscriptionScenarios[rand] !== 'undefined') {
    return subscriptionScenarios[rand]();
  }
  return 'Successfully canceled';
};
