import generateRandomNumber from '@commons/helpers/generators/generateRandomNumber';

const successfullChangePassword = () => ({
  success: true,
  data: {
    message: 'Password has been updated successfully.',
  },
});
const failedChangePassword = () => ({
  success: false,
  data: {
    message: "Password couldn't been updated successfully",
  },
});
const changePasswordScenario = [successfullChangePassword, failedChangePassword];
export const changePassword = () => {
  const rand = generateRandomNumber(0, changePasswordScenario.length);
  if (typeof changePasswordScenario[rand] !== 'undefined') {
    return changePasswordScenario[rand]();
  }
  return successfullChangePassword();
};
