import { userProfileUrls } from '@/api/apiUrls';
import { httpMethods } from '@/config/constants';
import http from '@/helpers/http';

// mock
import getKycStatusMock from './mocks/getKycStatus';
import getUserOverviewMock from './mocks/getUserOverview';
import { setupAntiPhishingCode } from './mocks/setupAntiPhishingCode';
import { changeEmailAddress } from './mocks/changeEmailAddress';
import { changePassword } from './mocks/changePassword';
import { changePhoneNumber } from './mocks/changePhoneNumber';
import { recoverTwoFactorAuth } from './mocks/recoverTwoFactorAuth';
import sendKycEmail from './mocks/sendLinkKyc';
import { setupPhoneNumber } from './mocks/setupPhoneNumber';
import updateUserProfile from './mocks/updateUserProfile';

// adapter
import adapterUserOverview from './adapter/getUserOverview';
import { changePhoneNumberAdapter, setupPhoneNumberAdapter } from './adapter/setupPhoneNumber';
import adapterGetKycStatus from './adapter/getKycStatus';
import changeEmailAddressAdapter from './adapter/changeEmailAddress';
import changePasswordAdapter from './adapter/changePassword';
import recoverTwoFactorAuthAdapter from './adapter/recoverTwoFactorAuth';
import sendKycEmailAdapter from './adapter/sendLinkKyc';
import setupAntiPhishingCodeAdapter from './adapter/setupAntiPhishingCode';

const getKycStatusService = async () => {
  return await http({
    method: httpMethods.get,
    urlService: userProfileUrls.getKycStatus(),
    mockdata: getKycStatusMock,
    adapter: adapterGetKycStatus,
    debug: false,
  });
};

const getUserOverviewService = async () => {
  return await http({
    method: httpMethods.get,
    urlService: userProfileUrls.getUserOverview(),
    mockdata: getUserOverviewMock,
    adapter: adapterUserOverview,
    debug: false,
  });
};
const updateUserProfileService = async ({ body }) => {
  return await http({
    method: httpMethods.post,
    urlService: userProfileUrls.updateUserProfile(),
    body,
    mockdata: updateUserProfile,
    adapter: adapterUserOverview,
    debug: false,
  });
};
const setupPhoneNumberService = async ({ body }) => {
  return await http({
    method: httpMethods.post,
    urlService: userProfileUrls.setUpPhoneNumber(),
    body,
    mockdata: setupPhoneNumber,
    adapter: setupPhoneNumberAdapter,
    debug: true,
  });
};
const changePhoneNumberService = async ({ body }) => {
  return await http({
    method: httpMethods.put,
    urlService: userProfileUrls.setUpPhoneNumber(),
    body,
    mockdata: changePhoneNumber,
    adapter: changePhoneNumberAdapter,
    debug: true,
  });
};
const changeEmailAddressService = async ({ body }) => {
  return await http({
    method: httpMethods.put,
    urlService: userProfileUrls.changeEmailAddress(),
    body,
    mockdata: changeEmailAddress,
    adapter: changeEmailAddressAdapter,
    debug: true,
  });
};
const changePasswordService = async (body) => {
  return await http({
    method: httpMethods.put,
    urlService: userProfileUrls.changePassword(),
    body,
    mockdata: changePassword,
    adapter: changePasswordAdapter,
    debug: false,
    contentType: 'application/json',
  });
};
const recoverTwoFactorAuthService = async ({ body }) => {
  return await http({
    method: httpMethods.post,
    urlService: userProfileUrls.recoverTwoFactorAuth(),
    body,
    mockdata: recoverTwoFactorAuth,
    adapter: recoverTwoFactorAuthAdapter,
    debug: true,
  });
};
const sendKycEmailService = async () => {
  return await http({
    method: httpMethods.get,
    urlService: userProfileUrls.sendKycEmail(),
    mockdata: sendKycEmail,
    adapter: sendKycEmailAdapter,
    debug: false,
  });
};
const setupAntiPhishingCodeService = async ({ body }) => {
  return await http({
    method: httpMethods.post,
    urlService: userProfileUrls.setupAntiPhishingCode(),
    body,
    mockdata: setupAntiPhishingCode,
    adapter: setupAntiPhishingCodeAdapter,
    debug: true,
  });
};
export {
  changePhoneNumberService,
  changeEmailAddressService,
  changePasswordService,
  getKycStatusService,
  getUserOverviewService,
  sendKycEmailService,
  recoverTwoFactorAuthService,
  setupAntiPhishingCodeService,
  setupPhoneNumberService,
  updateUserProfileService,
};
