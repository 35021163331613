import '@commons/styles/main.scss';
import '@commons/styles/vars.scss';
import '@/assets/styles/commons.scss';
import '@/assets/styles/toast.scss';
import { createApp } from 'vue';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import VueApexCharts from 'vue3-apexcharts';
import App from './App.vue';
import router from './router';
import store from './store';

(async () => {
  const app = createApp(App);

  app.use(store);
  await store.dispatch('user/initializeStore');
  app.use(router);
  app.use(Toast);
  app.use(VueApexCharts);
  app.mount('#app');
})();
