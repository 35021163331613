<template>
  <SpinnerLoading v-if="metaDataLoading" />
  <component :is="$route.meta.layoutComponent" v-else-if="!metaDataLoading">
    <router-view v-slot="{ Component }">
      <transition name="layout_fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </component>
</template>

<script setup>
import { computed, onBeforeMount, watch } from "vue";
import { onUpdated } from "vue";
import { RouterView } from "vue-router";
import SpinnerLoading from "./components/commons/SpinnerLoading.vue";
import { useAuth } from "@/composables/auth/useAuth";
import { useStore } from "vuex";

const { canAccessWallets } = useAuth();

const store = useStore();
const metaDataLoading = computed(
  () => store.getters["dashboard/meta_data"]._status._on_loading
);
const isAuthenticated = computed(() => store.getters["user/isAuthenticated"]);
const getMetaData = async () => {
  if (isAuthenticated.value) {
    await store.dispatch("dashboard/getMetaData");
  } else {
    return;
  }
};
onBeforeMount(async () => {
  await getMetaData();
});
watch(isAuthenticated, async (value) => {
  if (value) {
    await getMetaData();
  }
});

onUpdated(() => {
  if (canAccessWallets.value) {
    store.dispatch("wallet/getTotalBalance");
  }
});
</script>

<style scoped lang="scss">
.layout_fade-enter-active,
.layout_fade-leave-active {
  transition: all 200ms;
}

.layout_fade-enter-from,
.layout_fade-leave-to {
  opacity: 0;
}
</style>
