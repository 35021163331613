import { computed, watch } from 'vue';
import { useStore } from 'vuex';

export function useAuth() {
  const store = useStore();
  const metaDataReady = computed(() => store.getters['dashboard/meta_data']._status._on_ready);
  const meta_data = computed(() => store.getters['dashboard/meta_data']._data.data);
  const is_kyc_verified = computed(() => meta_data.value.is_kyc_verified);
  const has_subscription = computed(() => meta_data.value.has_subscription);
  const has_wallet = computed(() => meta_data.value.has_wallet);

  const canAccessSubscriptions = computed(() => {
    return is_kyc_verified.value && has_wallet.value;
  });
  const canAccessWallets = computed(() => {
    return is_kyc_verified.value && has_wallet.value;
  });
  const canAccessAlgorithms = computed(() => {
    return is_kyc_verified.value && has_subscription.value && has_wallet.value;
  });
  const canDeposit = computed(() => {
    return is_kyc_verified.value && has_wallet.value;
  });
  const canWithdrawal = computed(() => {
    return is_kyc_verified.value && has_wallet.value;
  });
  async function waitForMetaDataReady() {
    if (metaDataReady.value || !store.getters['user/isAuthenticated']) {
      return; // Already ready
    }

    // Create a Promise that resolves once metaDataReady is true
    return new Promise((resolve) => {
      const unwatch = watch(metaDataReady, (newValue) => {
        if (newValue) {
          unwatch(); // Stop watching once it's true
          resolve();
        }
      });
    });
  }
  return {
    canAccessSubscriptions,
    canAccessWallets,
    canAccessAlgorithms,
    canDeposit,
    canWithdrawal,
    metaDataReady,
    has_subscription,
    waitForMetaDataReady,
  };
}
