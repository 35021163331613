import { authUrls } from '@/api/apiUrls';
import http from '@/helpers/http';
// adapter
import adapterLogin from './adapters/login.adapter';
import adapterLogout from './adapters/logout';
import forgotAdapter from './adapters/forgot.adapter';
import resetAdapter from './adapters/reset.adapter';
import resendEmailVerificationAdapter from './adapters/resendEmailVerification.adapter';
// mock
import { mockLogin } from './mocks/login.mock';
import { mockLogout } from './mocks/logout';
import { mockForgot } from './mocks/forgot.mock';
import { mockReset } from './mocks/resetPassword.mock';
import { mockResendVerificationEmail } from './mocks/resendEmailVerification.mock';

const verifyEmailService = async (param) => {
  const { id, hash, ...rest } = param;
  return await http({
    method: 'GET',
    urlService: authUrls.verifyEmail(id, hash),
    params: rest,
    mockdata: mockResendVerificationEmail,
    adapter: resendEmailVerificationAdapter,
    debug: false,
  });
};

const resendVerificationEmailService = async (body) => {
  return await http({
    method: 'POST',
    urlService: authUrls.resendVerificationEmail(),
    body,
    mockdata: mockResendVerificationEmail,
    adapter: resendEmailVerificationAdapter,
    debug: false,
  });
};

const resetPasswordService = async (body) => {
  return await http({
    method: 'POST',
    urlService: authUrls.resetPassword(),
    body,
    mockdata: mockReset,
    adapter: resetAdapter,
    debug: false,
  });
};

const forgotPasswordService = async (body) => {
  return await http({
    method: 'POST',
    urlService: authUrls.forgotPassword(),
    body,
    mockdata: mockForgot,
    adapter: forgotAdapter,
    debug: false,
  });
};
const forgot2faService = async (body) => {
  return await http({
    method: 'POST',
    urlService: authUrls.forgot2fa(),
    body,
    mockdata: mockForgot,
    adapter: forgotAdapter,
    debug: true,
  });
};
const loginService = async (body) => {
  return await http({
    method: 'POST',
    urlService: authUrls.login(),
    body,
    mockdata: mockLogin,
    adapter: adapterLogin,
    debug: false,
  });
};

const logoutService = async (body) => {
  return await http({
    method: 'POST',
    urlService: authUrls.logout(),
    body,
    mockdata: mockLogout,
    adapter: adapterLogout,
    debug: false,
  });
};

export {
  loginService,
  logoutService,
  forgotPasswordService,
  forgot2faService,
  resetPasswordService,
  resendVerificationEmailService,
  verifyEmailService,
};
