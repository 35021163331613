import { deleteAllCookies } from '@/helpers/cookies/deleteAllCookies';
import router from '@/router';
import { logoutService } from '@/services/auth/authService';

const field = 'logout';

export default async ({ commit }) => {
  try {
    commit('setIsLoading', { field });
    deleteAllCookies();
    commit('removeUserSession');
    router.push({ name: 'login' });
    const { response } = await logoutService({});
    commit('setIsReady', { field });

    return response;
  } catch (error) {
    console.log('Error', error);
    commit('setIsError', { field });
    return {
      success: false,
    };
  }
};
