import { changeSubscriptionService as service } from '../../../../services/subscription/subscriptionService';

const field = 'change_subscription';

export default async ({ commit }, { id, body }) => {
  commit('setIsLoading', { field });

  try {
    const { response } = await service(id, { body });

    if (response.success) {
      commit('setIsReady', { field });
      commit('setData', {
        _state: field,
        _data: response,
      });
    } else {
      commit('setIsError', { field });
    }

    return response;
  } catch (error) {
    commit('setIsError', { field });
    throw error;
  }
};
