import store from '@/store';
import { useScreenSize } from '@commons/composables/useScreenSize';
import { createRouter, createWebHistory } from 'vue-router';
import { loadLayoutMiddleware } from './middleware/loadLayoutMiddleware';
import { accessRoutesMiddleware } from './middleware/accessRoutesMiddleware';
const { isMediumTablet, isSmallTablet } = useScreenSize();

const routes = [
  {
    path: '/',
    redirect: {
      name: 'dashboard',
    },
    meta: {
      auth_required: false,
    },
  },
  // auth routes start here
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/LoginComponent.vue'),
    meta: {
      layout: 'AuthLayoutComponent',
      auth_required: false,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/auth/RegisterComponent.vue'),
    meta: {
      layout: 'AuthLayoutComponent',
      auth_required: false,
    },
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import('@/views/auth/ForgotComponent.vue'),
    meta: {
      layout: 'AuthLayoutComponent',
      auth_required: false,
    },
  },
  {
    path: '/forgot-password-email',
    name: 'forgot-password-email',
    component: () => import('@/views/auth/ForgotPasswordEmailComponent.vue'),
    meta: {
      layout: 'AuthLayoutComponent',
      auth_required: false,
    },
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    component: () => import('@/views/auth/VerifyEmailComponent.vue'),
    meta: {
      layout: 'AuthLayoutComponent',
      auth_required: false,
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/profile/security/ResetPasswordView.vue'),
    beforeEnter: (to, from, next) => {
      if (to.query.token) {
        next();
      } else {
        next({ name: 'not-found' });
      }
    },
    meta: {
      layout: 'AuthLayoutComponent',
      auth_required: false,
    },
  },
  // auth routes end here
  // dashboard routes start here
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/DashboardPage.vue'),
    meta: {
      breadcrumb: [{ name: 'dashboard', breadcrumb: 'Home' }, { breadcrumb: 'Dashboard' }],
      layout: 'DashboardLayoutComponent',
      auth_required: true,
    },
  },
  // dashboard routes end here
  // algorithms routes start here
  {
    path: '/algorithms',
    name: 'algorithms',
    component: () => import('@/views/algorithms/AlgorithmsComponent.vue'),
    meta: {
      breadcrumb: [{ name: 'dashboard', breadcrumb: 'Home' }, { breadcrumb: 'algorithms' }],
      layout: 'DashboardLayoutComponent',
      auth_required: true,
    },
  },
  {
    path: '/algorithms/re-allocation',
    name: 're-allocation',
    component: () => import('@/views/algorithms/ReAllocationComponent.vue'),
    meta: {
      breadcrumb: [{ name: 'dashboard', breadcrumb: 'Home' }, { breadcrumb: 'algorithms' }],
      layout: 'DashboardLayoutComponent',
      auth_required: true,
    },
    beforeEnter: (to, from, next) => {
      if (isMediumTablet.value || isSmallTablet.value) {
        next();
      } else {
        next({ name: 'algorithms' });
      }
    },
  },
  {
    path: '/algorithms/allocation',
    name: 'allocation',
    component: () => import('@/views/algorithms/AllocationComponent.vue'),
    meta: {
      breadcrumb: [{ name: 'dashboard', breadcrumb: 'Home' }, { breadcrumb: 'algorithms' }],
      layout: 'DashboardLayoutComponent',
      auth_required: true,
    },
    beforeEnter: (to, from, next) => {
      if (isMediumTablet.value || isSmallTablet.value) {
        next();
      } else {
        next({ name: 'algorithms' });
      }
    },
  },
  {
    path: '/algorithms/:id',
    name: 'algorithm-details',
    component: () => import('@/views/AlgorithmDetails.vue'),
    meta: {
      breadcrumb: [{ name: 'algorithms', breadcrumb: 'Algorithms' }],
      layout: 'DashboardLayoutComponent',
    },
  },
  // algorithms routes end here
  // wallet routes start here
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import('@/views/WalletComponent.vue'),
    meta: {
      breadcrumb: [{ name: 'dashboard', breadcrumb: 'Home' }, { breadcrumb: 'wallet' }],
      layout: 'DashboardLayoutComponent',
      auth_required: true,
    },
  },
  // wallet routes end here
  // profile routes start here
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/ProfileComponent.vue'),
    redirect: {
      name: 'basic-information',
    },
    children: [
      {
        path: 'basic-info',
        name: 'basic-information',
        components: {
          middle: () => import('@/views/profile/BasicInfoView.vue'),
        },
      },
      {
        path: 'security',
        name: 'security',
        components: {
          middle: () => import('@/views/profile/security/SecurityView.vue'),
        },
      },
      {
        path: 'verification',
        name: 'verification',
        components: {
          middle: () => import('@/views/profile/VerificationView.vue'),
        },
      },
    ],
    meta: {
      breadcrumb: [
        { name: 'dashboard', breadcrumb: 'Home' },
        { name: 'profile', breadcrumb: 'Profile' },
      ],
      layout: 'DashboardLayoutComponent',
      auth_required: true,
    },
  },
  {
    path: '/profile/security/set-phone-number',
    name: 'set-phone-number',
    component: () => import('@/views/profile/security/SetPhoneNumber.vue'),
    meta: {
      layout: 'AuthLayoutComponent',
      auth_required: true,
    },
    beforeEnter: async (to, from, next) => {
      if (to.query.action == 'change') {
        next();
      } else {
        await store.dispatch('user/userProfile');
        if (store.state.user.userData._data.data.phone_number) {
          next({
            name: 'set-phone-number',
            query: {
              action: 'change',
            },
          });
        } else {
          next();
        }
      }
    },
  },
  {
    path: '/profile/security/change-email-address',
    name: 'change-email-address',
    component: () => import('@/views/profile/security/ChangeEmailAddressView.vue'),
    meta: {
      layout: 'AuthLayoutComponent',
      auth_required: true,
    },
  },
  {
    path: '/profile/security/recover-two-factor-auth',
    name: 'recover-two-factor-auth',
    component: () => import('@/views/profile/security/RecoverTwoMethodsAuthView.vue'),
    meta: {
      layout: 'AuthLayoutComponent',
      auth_required: true,
    },
  },
  {
    path: '/profile/security/anti-phishing-code',
    name: 'anti-phishing-code',
    component: () => import('@/views/profile/security/AntiPhishingCodeView.vue'),
    meta: {
      layout: 'AuthLayoutComponent',
      auth_required: true,
    },
  },
  {
    path: '/profile/security/change-password',
    name: 'change-password',
    component: () => import('@/views/profile/security/ChangePasswordView.vue'),
    meta: {
      layout: 'AuthLayoutComponent',
      auth_required: true,
    },
  },
  // profile routes end here
  // subscription routes start here
  {
    path: '/subscription',
    name: 'subscription',
    component: () => import('@/views/SubscriptionComponent.vue'),
    meta: {
      breadcrumb: [{ name: 'dashboard', breadcrumb: 'Home' }, { breadcrumb: 'subscription' }],
      layout: 'DashboardLayoutComponent',
      auth_required: true,
    },
  },
  {
    path: '/subscription/subscribe',
    name: 'subscribe',
    component: () => import('@/components/views/subscription/SubscriptionForm.vue'),
    meta: {
      breadcrumb: [{ name: 'dashboard', breadcrumb: 'Home' }, { breadcrumb: 'subscription' }],
      layout: 'DashboardLayoutComponent',
      auth_required: true,
    },
  },

  {
    path: '/not-found',
    name: 'not-found',
    component: () => import('@/views/notFound/NotFoundComponent.vue'),
    meta: {
      layout: 'AuthLayoutComponent',
      auth_required: false,
    },
  },
  {
    path: '/support-center',
    name: 'support-center',
    component: () => import('@/views/support/SupportCenter.vue'),
    meta: {
      layout: 'AuthLayoutComponent',
      auth_required: false,
    },
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: () => import('@/views/terms/TermsAndConditions.vue'),
    meta: {
      layout: 'AuthLayoutComponent',
      auth_required: false,
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/views/privacy/PrivacyPolicy.vue'),
    meta: {
      layout: 'AuthLayoutComponent',
      auth_required: false,
    },
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/faq/FAQView.vue'),
    meta: {
      layout: 'AuthLayoutComponent',
      auth_required: false,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'not-found',
    },
    meta: {
      layout: 'DefaultLayoutComponent',
      auth_required: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach(loadLayoutMiddleware);
router.beforeEach(accessRoutesMiddleware);
router.beforeEach(async (to, from, next) => {
  const isAuthenticated = await store.getters['user/isAuthenticated'];

  if (to.name === 'login' && isAuthenticated) {
    next({ name: 'dashboard' });
  }

  if (to.meta.auth_required && !isAuthenticated) {
    store.dispatch('user/logout');
    next({ name: 'login' });
  } else {
    next();
  }
});
export default router;
