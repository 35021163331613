const webserverUrl = process.env.VUE_APP_BASE_URL_SERVER;
const webserverPort = process.env.VUE_APP_BASE_URL_PORT;

export const baseUrl = `${webserverUrl}:${webserverPort}/api/v1`;

export const algorithmsUrls = {
  getAlgorithmList: () => `${baseUrl}/investor/algorithms`,
  getAlgorithmDetail: (id) => `${baseUrl}/investor/algorithms/${id}`,
  getAlgorithmTransactionList: (id) => `${baseUrl}/transactions/${id}`,
  algorithmReAllocation: (id, action) => `${baseUrl}/investor/portfolios/${id}/${action}`,
  getAvailableBalance: (id) => `${baseUrl}/available-balance/${id}`,
  algorithmAllocation: () => `${baseUrl}/investor/portfolios`,
  closeAllocatedAlgorithm: (id) => `${baseUrl}/investor/portfolios/${id}/close-all`,
};

export const authUrls = {
  login: () => `${baseUrl}/login`,
  logout: () => `${baseUrl}/logout`,
  forgotPassword: () => `${baseUrl}/forgot-password`,
  // resetPassword: (token) => `${baseUrl}/access/password/reset/?token=${token}`,
  resetPassword: () => `${baseUrl}/reset-password`,
  resendVerificationEmail: () => `${baseUrl}/email/verification-notification`,
  forgot2fa: () => `${baseUrl}/forgot-2fa`,
  verifyEmail: (id, hash) => `${baseUrl}/email/verify/${id}/${hash}`,
};

export const dashboardUrls = {
  downloadReport: () => `${baseUrl}/download-report`,
  getAlgorithmList: () => `${baseUrl}/investor/portfolios`,
  getAlgorithmPerformance: () => `${baseUrl}/dashboard/algorithm-performance`,
  getOverviewChart: () => `${baseUrl}/dashboard/overview`,
  getNetAssetValue: () => `${baseUrl}/dashboard/nav`,
  getPerformance: () => `${baseUrl}/investor/dashboard/performance-overview`,
  getRiskLevel: () => `${baseUrl}/investor/dashboard/risk-overview`,
  getTransactionList: () => `${baseUrl}/investor/transactions`,
  getTransactionListCount: () => `${baseUrl}/investor/transactions`,
  getMetaData: () => `${baseUrl}/investor/metadata`,
};

export const registerUrls = {
  register: () => `${baseUrl}/investor/register`,
};

export const subscriptionUrls = {
  calculateSubscription: () => `${baseUrl}/investor/plans/suggest`,
  cancelSubscription: () => `${baseUrl}/investor/subscriptions/cancel`,
  confirmSubscription: (id) => `${baseUrl}/investor/plans/${id}/subscribe`,
  changeSubscription: (id) => `${baseUrl}/investor/plans/${id}/update`,
  downloadPdf: () => `${baseUrl}/investor/subscriptions/download`,
  existingSubscription: () => `${baseUrl}/investor/subscriptions/my-subscription`,
  renewSubscription: () => `${baseUrl}/investor/subscriptions/renew`,
};

export const userProfileUrls = {
  getKycStatus: () => `${baseUrl}/investor/kyc/status`,
  getUserOverview: () => `${baseUrl}/investor/profile`,
  updateUserProfile: () => `${baseUrl}/investor/profile`,
  changePassword: () => `${baseUrl}/user/password`,
  setUpPhoneNumber: () => `${baseUrl}/user/phone-number`,
  changeEmailAddress: () => `${baseUrl}/user/change-email`,
  recoverTwoFactorAuth: () => `${baseUrl}/user/recover-2fa`,
  setupAntiPhishingCode: () => `${baseUrl}/user/anti-phishing-code`,
  sendKycEmail: () => `${baseUrl}/investor/kyc/link`,
};

export const walletUrls = {
  getNetworkList: () => `${baseUrl}/investor/networks`,
  getPortfolio: () => `${baseUrl}/investor/portfolios`,
  getTotalBalance: () => `${baseUrl}/investor/wallets/summary`,
  getWalletList: () => `${baseUrl}/investor/external-wallets`,
  addWalletAddress: () => `${baseUrl}/investor/external-wallets`,
  editWalletAddress: (id) => `${baseUrl}/investor/external-wallets/${id}`,
  getWalletSelectorList: () => `${baseUrl}/investor/external-wallets`,
  withdrawWallet: () => `${baseUrl}/investor/wallets/withdraw`,
  deleteWallet: () => `${baseUrl}/investor/external-wallets`,
  deleteWalletById: (id) => `${baseUrl}/investor/external-wallets/${id}`,
  markAsDefault: (id) => `${baseUrl}/wallets/${id}/default`,
  getDepositAddress: () => `${baseUrl}/investor/wallets/my-wallet`,
};

export const systemUrls = {
  getXsrf: () => `${baseUrl}/sanctum/csrf-cookie`,
};
