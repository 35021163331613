import generateLoadingMutations from '@commons/helpers/store/generateLoadingMutations';
import setData from '@commons/helpers/store/setData';
import setState from '@commons/helpers/store/setState';

import calculateSubscription from './actions/calculateSubscription';
import cancelSubscription from './actions/cancelSubscription';
import changeSubscription from './actions/changeSubscription';
import confirmSubscription from './actions/confirmSubscription';
import downloadPdf from './actions/downloadPdf';
import existingSubscription from './actions/existingSubscription';
import renewSubscription from './actions/renewSubscription';

import adapterCalculateSubscription from '@/services/subscription/adapters/calculateSubscription';
import adapterCancelSubscription from '@/services/subscription/adapters/cancelSubscription';
import adapterChangeSubscription from '@/services/subscription/adapters/changeSubscription';
import adapterConfirmSubscription from '@/services/subscription/adapters/confirmSubscription';
import adapterDownloadPdf from '@/services/subscription/adapters/downloadPdf';
import adapterExistingSubscription from '@/services/subscription/adapters/existingSubscription';
import adapterRenewSubscription from '@/services/subscription/adapters/renewSubscription';

const state = {
  calculate_subscription: setState(adapterCalculateSubscription),
  cancel_subscription: setState(adapterCancelSubscription),
  change_subscription: setState(adapterChangeSubscription),
  confirm_subscription: setState(adapterConfirmSubscription),
  downloadPdf: setState(adapterDownloadPdf),
  existing_subscription: setState(adapterExistingSubscription),
  renew_subscription: setState(adapterRenewSubscription),
};

const actions = {
  calculateSubscription,
  cancelSubscription,
  changeSubscription,
  confirmSubscription,
  downloadPdf,
  existingSubscription,
  renewSubscription,
};

const mutations = {
  ...generateLoadingMutations(),
  setData,
};

const getters = {
  calculate_subscription: (state) => state.calculate_subscription,
  existing_subscription: (state) => state.existing_subscription,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
