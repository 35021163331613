import randomNumber from '@commons/helpers/generators/generateRandomNumber';

const failedResendVerificationEmail = () => ({
  success: false,
  message: 'You tried to resend verification email too many times. Please try again later.',
});

const successfullResendVerificationEmail = () => ({
  success: true,
  messages: 'Verification Email has been sent successfully.',
});

const resendVerificationEmailScenarios = [
  successfullResendVerificationEmail,
  failedResendVerificationEmail,
];

export const mockResendVerificationEmail = () => {
  const rand = randomNumber(0, resendVerificationEmailScenarios.length);

  if (typeof resendVerificationEmailScenarios[rand] !== 'undefined') {
    return resendVerificationEmailScenarios[rand]();
  }

  return successfullResendVerificationEmail();
};
