const metaDataMock = () => ({
  success: true,
  data: {
    has_subscription: false,
    has_subaccount: false,
    has_wallet: true,
    is_kyc_verified: true,
  },
});

export default metaDataMock;
