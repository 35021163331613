import { registerService as service } from '@/services/register/registerService';

const field = 'register';

export default async ({ commit }, { body }) => {
  try {
    commit('setIsLoading', { field });
    const { response } = await service({ body });
    // if (response.success) {
    //   const customResponse = {
    //     success: response.success,
    //     data: response.data.user,
    //     token: response.data?.session?.jwt_token,
    //     authorized: true,
    //     cookie_auth: '', // no cookie_auth from backend , will change it later when we get cookie_auth from backend
    //   };

    //   await loginByResponse(commit, customResponse);
    //   commit('setData', {
    //     _state: field,
    //     _data: response,
    //   });
    // } else {
    //   commit('setIsError', { field });
    // }

    return response;
  } catch (error) {
    commit('setIsError', { field });
    throw error;
  } finally {
    commit('setIsReady', { field });
  }
};
