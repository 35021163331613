import { dashboardUrls } from '@/api/apiUrls';
import { httpMethods } from '../../config/constants';
import http from '@/helpers/http';

// Adapters
import adapterAlgorithmsList from './adapters/getDashboardAlgorithmsList';
import adapterDownloadReport from '../common/adapters/dataResJson';
import adapterGetAlgorithmPerformance from './adapters/getAlgorithmPerformance';
import adapterGetNetAssetValue from './adapters/getNetAssetValue';
import adapterGetOverviewChart from './adapters/getOverviewChart';
import adapterGetPerformance from './adapters/getPerformance';
import adapterGetRiskLevel from './adapters/getRiskLevel';
import adapterGetTransactionList from './adapters/getTransactionList';
import adapterGetTransactionListCount from './adapters/getTransactionListCount';
import adapterGetMetaData from './adapters/getMetaData';

// Mockdata
import algorithmPerformanceMock from './mocks/getAlgorithmPerformance';
import dashboardAlgorithmsListMock from './mocks/getDashboardAlgorithmsList';
import getTransactionListMock from './mocks/getTransactionList';
import getTransactionListCountMock from './mocks/getTransactionListCount';
import { mockDownloadReport } from './mocks/downloadReport';
import { mockOverviewChart } from './mocks/getOverviewChart';
import netAssetValueMock from './mocks/getNetAssetValue';
import performanceMock from './mocks/getPerformance';
import riskLevelMock from './mocks/getRiskLevel';
import metaDataMock from './mocks/getMetaData';

const getMetaDataService = async () => {
  return await http({
    method: httpMethods.get,
    urlService: dashboardUrls.getMetaData(),
    mockdata: metaDataMock,
    adapter: adapterGetMetaData,
    debug: false,
  });
};

const downloadReportService = async () => {
  return await http({
    method: httpMethods.post,
    urlService: dashboardUrls.downloadReport(),
    mockdata: mockDownloadReport,
    adapter: adapterDownloadReport,
    debug: true,
  });
};

const getAlgorithmPerformanceService = async () => {
  return await http({
    method: httpMethods.get,
    urlService: dashboardUrls.getAlgorithmPerformance(),
    mockdata: algorithmPerformanceMock,
    adapter: adapterGetAlgorithmPerformance,
    debug: true,
  });
};

const getDashboardAlgorithmsList = async () => {
  return await http({
    method: httpMethods.get,
    urlService: dashboardUrls.getAlgorithmList(),
    mockdata: dashboardAlgorithmsListMock,
    adapter: adapterAlgorithmsList,
    debug: false,
  });
};

const getNavService = async () => {
  return await http({
    method: httpMethods.get,
    urlService: dashboardUrls.getNetAssetValue(),
    mockdata: netAssetValueMock,
    adapter: adapterGetNetAssetValue,
    debug: true,
  });
};

const getOverviewChartService = async () => {
  return await http({
    method: httpMethods.get,
    urlService: dashboardUrls.getOverviewChart(),
    mockdata: mockOverviewChart,
    adapter: adapterGetOverviewChart,
    debug: true,
  });
};

const getPerformanceService = async () => {
  return await http({
    method: httpMethods.get,
    urlService: dashboardUrls.getPerformance(),
    mockdata: performanceMock,
    adapter: adapterGetPerformance,
    debug: false,
  });
};

const getRiskLevelService = async () => {
  return await http({
    method: httpMethods.get,
    urlService: dashboardUrls.getRiskLevel(),
    mockdata: riskLevelMock,
    adapter: adapterGetRiskLevel,
    debug: false,
  });
};

const getTransactionListService = async ({ params }) => {
  return await http({
    method: httpMethods.get,
    urlService: dashboardUrls.getTransactionList(),
    params,
    mockdata: getTransactionListMock,
    adapter: adapterGetTransactionList,
    debug: false,
  });
};
const getTransactionListCountService = async ({ params }) => {
  return await http({
    method: httpMethods.get,
    urlService: dashboardUrls.getTransactionListCount(),
    params,
    mockdata: getTransactionListCountMock,
    adapter: adapterGetTransactionListCount,
    debug: false,
  });
};

export {
  downloadReportService,
  getAlgorithmPerformanceService,
  getDashboardAlgorithmsList,
  getNavService,
  getOverviewChartService,
  getPerformanceService,
  getRiskLevelService,
  getTransactionListService,
  getTransactionListCountService,
  getMetaDataService,
};
