import randomDecimal from '@commons/helpers/generators/generateRandomDecimal';

export default () => ({
  success: true,
  data: {
    address: 'ToF58dY7klXqI3SfqkhgvM5ddOeAQez9yq',
    currency: 'USDT',
    available_balance: randomDecimal(1000000, 2),
  },
});
