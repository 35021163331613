import { algorithmsUrls } from '@/api/apiUrls';
import { httpMethods } from '../../config/constants';
import http from '@/helpers/http';

// Adapters
import adapterGetAlgorithmList from './adapters/getAlgorithmList';
import adapterGetAlgorithmTransactionList from './adapters/getAlgorithmTransactionList';
import adapterGetAvailableBalance from './adapters/getAvailableBalance';
import adapterAlgorithmAllocation from './adapters/algorithmAllocation';
import adapterReAlgorithmAllocation from './adapters/algorithmReAllocation';
import adapterCloseAllocatedAlgo from './adapters/closeAllocatedAlgo';
import adapterAlgorithmDetail from './adapters/getAlgorithmDetail';
// Mockdata
import getAlgorithmListMock from './mocks/getAlgorithmList';
import getAlgorithmTransactionListMock from './mocks/getAlgorithmTransactionList';
import getAvailableBalanceMock from './mocks/getAvailableBalance';

import { mockAlgorithmAllocation } from './mocks/algorithmAllocation';
import { mockReAlgorithmAllocation } from './mocks/algorithmReAllocation';
import { mockCloseAlgorithmAllocation } from './mocks/closeAllocatedAlgo';
import getAlgorithmDetail from './mocks/getAlgorithmDetail';

const getAlgorithmListService = async ({ params }) => {
  return await http({
    method: httpMethods.get,
    urlService: algorithmsUrls.getAlgorithmList(),
    params,
    mockdata: getAlgorithmListMock,
    adapter: adapterGetAlgorithmList,
    debug: false,
    contentType: 'application/json',
  });
};
const getAlgorithmDetailService = async ({ id, params }) => {
  return await http({
    method: httpMethods.get,
    urlService: algorithmsUrls.getAlgorithmDetail(id),
    mockdata: getAlgorithmDetail,
    params,
    adapter: adapterAlgorithmDetail,
    debug: false,
    contentType: 'application/json',
  });
};

const getAlgorithmTransactionListService = async ({ id }) => {
  return await http({
    method: httpMethods.get,
    urlService: algorithmsUrls.getAlgorithmTransactionList(id),
    mockdata: getAlgorithmTransactionListMock,
    adapter: adapterGetAlgorithmTransactionList,
    debug: true,
  });
};

const getAvailableBalanceService = async ({ id }) => {
  return await http({
    method: httpMethods.get,
    urlService: algorithmsUrls.getAvailableBalance(id),
    mockdata: getAvailableBalanceMock,
    adapter: adapterGetAvailableBalance,
    debug: true,
    contentType: 'application/json',
  });
};
const algorithmAllocationService = async ({ body }) => {
  return await http({
    method: httpMethods.post,
    urlService: algorithmsUrls.algorithmAllocation(),
    mockdata: mockAlgorithmAllocation,
    adapter: adapterAlgorithmAllocation,
    body,
    debug: false,
    contentType: 'application/json',
  });
};
const algorithmReAllocationService = async ({ body }) => {
  const { algorithm_id, action, amount, percentage } = body;
  let payload = {};
  action === 'increase' ? (payload.amount = amount) : (payload.percentage = percentage);
  return await http({
    method: httpMethods.patch,
    urlService: algorithmsUrls.algorithmReAllocation(algorithm_id, action),
    mockdata: mockReAlgorithmAllocation,
    adapter: adapterReAlgorithmAllocation,
    body: payload,
    contentType: 'application/json',
    debug: false,
  });
};
const closeAllocatedAlgorithmService = async ({ params }) => {
  return await http({
    method: httpMethods.patch,
    urlService: algorithmsUrls.closeAllocatedAlgorithm(params),
    mockdata: mockCloseAlgorithmAllocation,
    adapter: adapterCloseAllocatedAlgo,
    contentType: 'application/json',
    debug: false,
  });
};

export {
  getAlgorithmListService,
  getAlgorithmTransactionListService,
  getAvailableBalanceService,
  algorithmAllocationService,
  algorithmReAllocationService,
  closeAllocatedAlgorithmService,
  getAlgorithmDetailService,
};
