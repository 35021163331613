import { riskLevel, algorithmsName } from '@/config/constants';
import randomNumber from '@commons/helpers/generators/generateRandomNumber';
import randomDecimal from '@commons/helpers/generators/generateRandomDecimal';

export default () => ({
  success: true,
  data: {
    investment_amount: randomNumber(0, 1000),
    subscription_amount: randomNumber(0, 1000),
    shared_profit: randomNumber(0, 100),
    reference_number: randomNumber(10000, 10000000),
    algorithms: [
      {
        id: randomNumber(0, 100000),
        name: algorithmsName[randomNumber(0, algorithmsName.length)],
        estimated_profits: randomDecimal(1000000, 2),
        estimated_profits_percentage: randomNumber(0, 100),

        risk: riskLevel[randomNumber(0, riskLevel.length)],
        performance: [
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
        ],
      },
      {
        id: randomNumber(0, 100000),
        name: algorithmsName[randomNumber(0, algorithmsName.length)],
        estimated_profits: randomDecimal(1000000, 2),
        estimated_profits_percentage: randomNumber(0, 100),
        risk: riskLevel[randomNumber(0, riskLevel.length)],
        performance: [
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
        ],
      },
      {
        id: randomNumber(0, 100000),
        name: algorithmsName[randomNumber(0, algorithmsName.length)],
        estimated_profits: randomDecimal(1000000, 2),
        estimated_profits_percentage: randomNumber(0, 100),
        risk: riskLevel[randomNumber(0, riskLevel.length)],
        performance: [
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
        ],
      },
      {
        id: randomNumber(0, 100000),
        name: algorithmsName[randomNumber(0, algorithmsName.length)],
        estimated_profits: randomDecimal(1000000, 2),
        estimated_profits_percentage: randomNumber(0, 100),
        risk: riskLevel[randomNumber(0, riskLevel.length)],
        performance: [
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
          randomDecimal(1000000, 2),
        ],
      },
    ],
  },
});
