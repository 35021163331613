import { useAuth } from '@/composables/auth/useAuth';

export async function accessRoutesMiddleware(route) {
  const { canAccessAlgorithms, canAccessWallets, canAccessSubscriptions, waitForMetaDataReady } =
    useAuth();
  await waitForMetaDataReady();
  try {
    switch (route.name) {
      case 'algorithms':
      case 'algorithm-details':
      case 'allocation':
      case 're-allocation':
        if (!canAccessAlgorithms.value) {
          return { name: 'dashboard' };
        }
        break;
      case 'wallet':
        if (!canAccessWallets.value) {
          return { name: 'dashboard' };
        }
        break;
      case 'subscription':
      case 'subscribe':
        if (!canAccessSubscriptions.value) {
          return { name: 'dashboard' };
        }
        break;
      default:
        break;
    }
  } catch (e) {
    console.error('Error occurred in processing of routes guard: ', e);
  }
}
