import { useToast } from 'vue-toastification';
// components
import CommonToast from '@/components/toast/CommonToast.vue';
import CommonToastForMobile from '@/components/toast/CommonToastForMobile.vue';
import { useScreenSize } from '@commons/composables/useScreenSize';
// composables
export function useCustomToast(content) {
  const { isMobile } = useScreenSize();
  const toast = useToast();
  const newContent = {
    component: !isMobile?.value ? CommonToast : CommonToastForMobile,
    props: {
      text: content.text,
      type: content.type,
      title: content.title,
    },
  };
  const Position = !isMobile?.value
    ? content.position
      ? content.position
      : 'bottom-right'
    : 'bottom-center';
  const options = {
    position: Position,
    timeout: !isMobile?.value ? (content.timeout ? content.timeout : 3000) : null,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: false,
    icon: false,
    rtl: false,
  };
  if (content.type == 'error') {
    toast.error(newContent, options);
  } else if (content.type == 'success') {
    toast.success(newContent, options);
  } else if (content.type == 'warning') {
    toast.warning(newContent, options);
  } else if (content.type == 'info') {
    toast.info(newContent, options);
  }
}
